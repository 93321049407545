.input-form-content  {

	.space {
		margin-left: $marginMedium;
	}

	.label-gray {
		background-color: #eaeaea;
    	padding: 2px 16px;
	}

	.text-area-gray {
		background-color: #eaeaea;
		padding: 8px;
		width: 100%;
		font-size: $fontText;
		//min-height: 100px;
	}

/* Estilos label para filas con un solo input */

	.label-normal { 
		width: 20%;
	}

	.label-extra { 
		width: 31%;
	}

	.label-medium { 
		width: 35%;
	}

	.label-big { 
		width: 45%;
	}

/* Estilos label para filas con dos inputs */
	.label-duo-huge {
		width: 115%;
	}

	.label-duo-big {
		width: 90%;
	}

	.label-duo-normal {
		width: 53%;
	}

/* Estilos label para filas con tres inputs */

	.label-triple-huge {
		width: 130%;
	}

}

/* Estilos nueva área común */

.column-img {
	width: 75%;
}