// COLORES PRINCIPALES DEL MODAL

$success: #6c757d;
$successLight: #6c757d86;
$cancel: #ea4235;
$cancelLight: #eb5b51;

$overlay: rgba(0, 0, 0, 0.5);
$header: #000000;
$hoverBtnClose: #6c757d86;
$background:#FFFFFF;

// ESTILOS GENERALES 
@mixin button-action {
	width: 25%;
	padding: 6px 10px;
	margin-right: 8px;
	font-size: 1rem;
	font-weight: 800;
	color: white;
	transition: all 300ms ease-in-out;
	i {
		font-size: 1rem;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		text-shadow: 0px 0px 6px rgba(255, 255, 255, .4);
		-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
		-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
		transition: all 0.4s ease 0s;
	}
}
// TAMAÑO MODAL

.dinamic-modal-small {
	width: 45%;
}

.dinamic-modal-normal {
	width: 50%;
}

.dinamic-modal-big {
	width: 57%;
}
// ESTILOS CUERPO MODAL

.dinamic-modal-container {
	top: 50%;
    left: 50%; 
    outline: none;
    max-height: 90%;
   	//overflow-y: scroll;
    position: absolute;
    border-radius: 4px;
    //padding: $paddingMedium;
	background-color: $white;
	transition: max-height 1.5s ease-in-out;
    transform: translate(-50%, -50%);
	z-index: 2;
	
	.title {
		text-align: left;
	}

	.modal-container {
		width: 90%;
	}

	.dinamic-header {
		padding: 8px 0px 8px 0px;
		border-radius: 4px 4px 0 0;
		background-color: $black;
		width: 100%;
		height: 60px;
		position: relative;
		.title {
			color: white;
			font-weight: 500;
		}

		.btn-modal-close {
			position: absolute;
			right: 2.3%;
			top: 19%;
			height: 35px;
			width: 35px;
			border-radius: 50%;
			background-color: $header;
			color: white;
			font-size: 1.4rem;
			transition: background-color 400ms ease-in-out;

			&:hover {
				//color: $primary;
				background-color: $successLight;
			}
		}

	}

	.dinamic-body {
		height: auto;
		max-height: 500px;
		overflow-y: scroll;
	}

	.dinamic-footer {
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.btn-action-success {
			@include button-action;
			background-color: $success;

			&:hover {
				background-color: #8c949b;
			}
		}

		.btn-action-cancel {
			@include button-action;
			background-color: $cancel;

			&:hover {
				background-color: $cancelLight;
			}
		}
	}
}

.overlay {
	top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: fixed;
    transition: height 1500ms ease-in-out;
    background-color: $overlay;
    ::-webkit-scrollbar { width: 6px; }
	::-webkit-scrollbar-thumb { background-color: #cccccc;}
}

.show-dinamic-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide-dinamic-modal {
	opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}