@import url(https://use.fontawesome.com/releases/v5.8.1/css/all.css);
@charset "UTF-8";
/**
*   style.scss
*   @description: Constructor de la hoja de estilos
*   @variables: dev: boolean: Activa las opciones de desarrollo
*/
/*  
*   1.- Core Styles: trejocode.css
*   2.- Componentes: Todos los elementos posibles
*/
/*      Core       */
/**
*   dev.scss
*   @description: Todas las opciones de desarrollo
*/
/*  INFORMATION
   =============================
    
	- File:           trejocode.css
	- Versión:        1.2
	- Licence:        Mozilla Public Licence. 
	- Author:         TrejoCode
    - Update:         11/06/2019
    - Client:         iGate
*/
/* RESET
   =============================
   - These properties able the cross compatibility for browsers and removes default properties from browsers.
*/
* {
  padding: 0;
  margin: 0; }

html {
  font-size: 100%; }

*, *:before, *:after {
  box-sizing: border-box; }

body {
  overflow-x: hidden;
  background-color: #F5F4F4; }

input, textarea, select, option, button {
  margin: 0;
  border: 0;
  padding: 0;
  outline: none; }

table {
  border-spacing: 0;
  overflow-x: auto; }

table th {
  white-space: nowrap; }

input:focus, select:focus {
  outline: none;
  -webkit-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out; }

ul, ol {
  list-style: none; }

a {
  color: inherit;
  word-break: keep-all;
  text-decoration: none;
  align-self: flex-start;
  cursor: pointer; }

img {
  max-width: 100%;
  height: auto; }

/*div, header, footer, section, article, aside, nav, form, table {
    width: 100%;
    transition: all 450ms;
}*/
/* CLASES
   =============================
	1. = FLEX LAYOUT
	2. = ALIGNMENT
    3. = WHITE SPACES
    4. = TABLES
    5. = IMAGES
    6. = DECORATORS & COLORS
*/
/*    FLEX LAYOUT
    - This stylesheet is based on flexbox.
    - W3 CSS Flexible Box Layout Module:
       https://www.w3.org/TR/css-flexbox-1
    
*/
.flex {
  width: 100%;
  display: flex; }

.row-reverse {
  flex-direction: row-reverse;
  display: flex; }

.container {
  width: 90%;
  display: flex; }

.responsive-img {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; }

.row {
  display: flex;
  flex-direction: row; }

.row-responsive {
  display: flex;
  flex-direction: row; }

.column {
  display: flex;
  flex-direction: column; }

.wrap {
  flex-wrap: wrap;
  display: flex; }

/*    ALIGNMENT    */
.justify-center {
  width: 100%;
  display: flex;
  justify-content: center; }

.justify-start {
  width: 100%;
  display: flex;
  justify-content: flex-start; }

.justify-end {
  width: 100%;
  display: flex;
  justify-content: flex-end; }

.justify-between {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.justify-around {
  width: 100%;
  display: flex;
  justify-content: space-around; }

.align-center {
  width: 100%;
  display: flex;
  align-items: center; }

.align-start {
  width: 100%;
  display: flex;
  align-items: flex-start; }

.align-end {
  width: 100%;
  display: flex;
  align-items: flex-end; }

.auto {
  width: auto;
  display: flex; }

.full {
  width: 100%; }

/*    TEXT ALIGNMENT    */
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-right {
  text-align: right; }

/*    TABLES    */
.table-responsive {
  overflow-x: auto; }

.table-responsive table {
  width: 100%; }

.table-auto {
  table-layout: auto; }

.table-equal {
  table-layout: fixed; }

/*    IMAGES    */
.cover-img {
  object-fit: cover; }

.contain-img {
  object-fit: contain; }

/*    DECORATORS    */
.hidden {
  display: none !important; }

.cursor-pointer {
  cursor: pointer; }

/*    LARGE DEVICES    */
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1440px; } }

/*    NOTEBOOKS, IPADS DEVICES    */
/*    TABLETS DEVICES    */
@media screen and (max-width: 768px) {
  .row-responsive {
    flex-direction: column; }
  .white-space-64 {
    height: 32px; } }

/*    MOBILE DEVICES    */
@media screen and (max-width: 576px) {
  .container {
    width: 92%; } }

/**
*   colors.scss
*   @description: Variables de colores
*/
/* Classes de colores */
.color-white {
  color: #FFFFFF; }

.color-black {
  color: #000000; }

.color-dark {
  color: #4e4a4a; }

.color-primary {
  color: #ea4235; }

.color-secondary {
  color: #6c757d; }

.color-success {
  color: #28a745; }

.color-info {
  color: #17a2b8; }

.color-warning {
  color: #ffc107; }

.color-danger {
  color: #ea4235; }

.color-green {
  color: #28a745; }

.color-red {
  color: #dc3545; }

/**
*   fonts.scss
*   @description: Estilos de fuente y tipografía
*/
/*    Font families    */
/*    Font sizes    */
.font-double {
  font-size: 2rem; }

.font-huge {
  font-size: 1.8rem; }

.font-large {
  font-size: 1.6rem; }

.font-medium {
  font-size: 1.4rem; }

.font-regular {
  font-size: 1.2rem; }

.font-small {
  font-size: 1rem; }

.font-text {
  font-size: 0.93rem; }

.font-tiny {
  font-size: 0.9rem; }

.font-mini {
  font-size: 0.8rem; }

/*    Font weight   */
.weight-bold {
  font-weight: 700; }

.weight-semi {
  font-weight: 600; }

.weight-medium {
  font-weight: 500; }

.weight-regular {
  font-weight: 400; }

a {
  font-family: "Mukta", sans-serif;
  font-size: 1rem; }

li {
  font-family: "Mukta", sans-serif;
  font-size: 1rem; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
b,
span,
td,
td,
tr {
  color: #4e4a4a;
  font-family: "Mukta", sans-serif; }

h1 {
  font-size: 1.8rem;
  font-weight: 700; }

h2 {
  font-size: 1.6rem;
  font-weight: 700; }

h3 {
  font-size: 1.4rem;
  font-weight: 600; }

h4 {
  font-size: 1.2rem;
  font-weight: 500; }

h5 {
  font-size: 0.93rem;
  font-weight: 500; }

h6 {
  font-size: 1.4rem;
  font-weight: 400; }

p,
b,
span {
  font-size: 0.93rem;
  line-height: 22px;
  color: #000000; }

/*   Decorators    */
.uppercase {
  text-transform: uppercase; }

.lowercase {
  text-transform: lowercase; }

.capitalize {
  text-transform: capitalize; }

.underline {
  text-decoration: underline; }

.line-through {
  text-decoration: line-through; }

.truncate-ellipsis {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

::-moz-selection {
  color: #FFFFFF;
  background-color: #000000; }

::selection {
  color: #FFFFFF;
  background-color: #000000; }

/**
*   animations.scss
*   @description: Todas las clases de animación, inclusive externos
*/
/**
*   spacing.scss
*   @description: Espacios en blanco, margenes y paddings
*/
/*      Paddings       */
/*      Margins       */
/*      Whitespaces       */
.white-space-4 {
  height: 4px; }

.white-space-8 {
  height: 8px; }

.white-space-16 {
  height: 16px; }

.white-space-24 {
  height: 24px; }

.white-space-32 {
  height: 32px; }

.white-space-48 {
  height: 48px; }

.white-space-64 {
  height: 64px; }

/*      Helpers      */
/**
*   bg.scss
*   @description: Propiedades de los backgrounds
*/
/*      Componentes      */
/**
*   button.scss
*   @description: Estilos para los botones
*/
/*---------------------- ESTILOS EN USO ------------------------*/
.btn {
  cursor: pointer;
  font-family: "Mukta", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
  padding: 16px 24px;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }
  .btn:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }

.btn-cancel {
  cursor: pointer;
  font-family: "Mukta", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
  padding: 16px 24px;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  border-bottom-right-radius: 4px;
  background: #6c757d;
  color: white; }
  .btn-cancel:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }

.btn-modal-delete-cancel {
  cursor: pointer;
  font-family: "Mukta", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
  padding: 16px 24px;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  width: 50%;
  border-bottom-right-radius: 4px;
  background: #6c757d;
  color: white; }
  .btn-modal-delete-cancel:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  .btn-modal-delete-cancel:hover {
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.5);
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s; }

.btn-modal-delete-success {
  cursor: pointer;
  font-family: "Mukta", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
  padding: 16px 24px;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  width: 50%;
  border-bottom-left-radius: 4px;
  background: #ea4235;
  color: white; }
  .btn-modal-delete-success:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  .btn-modal-delete-success:hover {
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.5);
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s; }

.btn-disable {
  border-radius: 2px;
  background-color: rgba(159, 159, 159, 0.8); }

/*----------------------------------------------------------------*/
button {
  cursor: pointer;
  font-family: "Mukta", sans-serif;
  background-color: transparent; }
  button:disabled {
    cursor: not-allowed; }

.btn-mini {
  padding: 2px 7px;
  font-size: .8rem; }

.btn-medium {
  width: 50%; }

.btn-full {
  width: 100% !important; }

.btn-primary {
  border-radius: 2px;
  background-color: #ea4235; }
  .btn-primary:hover {
    background-color: rgba(234, 66, 53, 0.8); }

.btn-secondary {
  border-radius: 2px;
  background-color: #6c757d; }
  .btn-secondary:hover {
    background-color: rgba(108, 117, 125, 0.8); }

.btn-small {
  padding: 8px 16px; }

.btn-filter {
  cursor: pointer;
  background-color: #000000;
  color: #FFFFFF;
  border-radius: 2px;
  font-size: 1rem;
  font-weight: 700;
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);*/
  -webkit-transition: background-color 300ms ease-in-out;
  transition: background-color 300ms ease-in-out; }
  .btn-filter:hover {
    background-color: #ea4235; }

.btn-transparent {
  -webkit-transition: color 300ms ease-in-out;
  transition: color 300ms ease-in-out;
  background-color: transparent;
  color: black; }
  .btn-transparent:hover {
    background-color: transparent;
    color: #ea4235; }

.btn-buscar .btn-primary {
  margin-left: 6px; }

.btn-horarios .btn-secondary {
  border-radius: 2px;
  color: white !important;
  background-color: #6c757d !important; }
  .btn-horarios .btn-secondary:hover {
    background-color: rgba(108, 117, 125, 0.8); }

/**
*   inputs.scss
*   @description: Estilos para los inputs
*/
input, select, option, textarea {
  font-family: "Mukta", sans-serif; }

select:disabled {
  background-color: #EBEBE4; }

.input {
  color: #4e4a4a;
  padding: 8px;
  border-bottom: 2px solid rgba(159, 159, 159, 0.5); }
  .input:focus {
    border-bottom: 2px solid #ea4235; }

/*-------- Input modales ---------- */
.input-modals {
  width: 100%;
  padding: 4px 2px;
  font-size: 0.93rem; }

/*----------------------------------*/
.search-column {
  position: relative; }
  .search-column .btn-buscar {
    position: absolute;
    right: 2px; }

.input-select {
  border-radius: 4px;
  padding: 8px;
  font-weight: 600; }

.incidents .input-select, .guests .input-select {
  width: 200px;
  margin-left: 16px; }

.textarea {
  resize: none;
  line-height: 24px;
  padding: 8px;
  background-color: #f2f2f3; }

.radio {
  top: 0;
  border: 0;
  height: 20px;
  width: 20px;
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  color: #666666;
  background-color: #f1f1f1;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  margin-right: 16px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }

.radio:checked::before {
  left: 6px;
  font-size: 0.8rem;
  content: '\02143';
  position: absolute;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg); }

.radio:hover {
  background-color: #dedede; }

.radio:checked {
  color: #FFFFFF;
  background-color: #ea4235; }

.radio-label {
  color: #4e4a4a;
  cursor: pointer;
  font-family: "Mukta", sans-serif;
  font-size: 1rem;
  margin-right: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.input-filter {
  padding: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
  border-radius: 4px; }

.input-modals-small {
  width: 40%;
  padding: 4px 2px; }

.modal-incidents #incident-resolution {
  border-radius: 0;
  background-color: #f2f2f3; }

.input-year {
  width: 30%;
  padding: 4px 8px;
  margin-left: 16px; }

.modal-houses .input {
  width: 100%;
  margin-left: 8px; }

.input-description {
  border: 1px solid #3b3b3b; }

.modal-houses .input-select-type {
  width: 75%; }

.width-45 {
  width: 45%; }

.input-clean {
  position: relative; }
  .input-clean input {
    padding-right: 25px; }
  .input-clean i {
    height: 100%;
    right: 10px;
    top: 10px;
    position: absolute;
    cursor: pointer;
    z-index: 2; }

/* The container */
.container-check {
  display: block;
  height: 25px;
  width: 25px;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: lightgray; }

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
  background-color: #ccc; }

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  background-color: #ea4235; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.input-select-configuration {
  width: 20%;
  padding: 0;
  border-radius: 2px;
  border: 1px solid rgba(159, 159, 159, 0.5); }

/**
*   header.scss
*   @description: Estilos del Header
*/
header {
  padding: 8px 0;
  background-color: #000000;
  z-index: 2; }
  header .options {
    width: 25%; }
  header img {
    height: 60px;
    width: 60px; }
  header .nav-item {
    padding: 0 8px;
    border-bottom: 2px solid transparent;
    -webkit-transition: border-bottom 300ms ease-in-out;
    transition: border-bottom 300ms ease-in-out;
    margin-left: 8px;
    padding: 4px 4px; }
    header .nav-item:hover {
      border-bottom: 2px solid #FFFFFF; }
    header .nav-item i {
      padding-right: 4px; }
  header .nav-active {
    border-bottom: 2px solid #dc3545;
    margin-left: 8px;
    padding: 4px 4px; }

.header-modals {
  padding: 8px 8px 8px 16px;
  border-radius: 4px 4px 0 0;
  background-color: #000000;
  width: 100%;
  position: relative; }
  .header-modals .btn-modal-close {
    position: absolute;
    right: 16px;
    top: 10px;
    color: white;
    font-size: 1rem; }
    .header-modals .btn-modal-close:hover {
      color: #ea4235;
      -webkit-transition: color 300ms ease-in-out;
      transition: color 300ms ease-in-out; }

.header-close {
  border-radius: 4px 4px 0 0;
  background-color: #000000;
  width: 100%; }
  .header-close .btn-modal-close {
    color: white;
    font-size: 1.2rem; }
    .header-close .btn-modal-close:hover {
      color: #ea4235;
      -webkit-transition: color 300ms ease-in-out;
      transition: color 300ms ease-in-out; }

.header-slide {
  /*.logo {
        width: 26%;
    }*/ }
  .header-slide .logo {
    width: 70%; }
  .header-slide .options .icon-main {
    color: #FFFFFF;
    -webkit-transition: color .300s ease-in-out;
    transition: color .300s ease-in-out;
    font-size: 1.2rem;
    cursor: pointer; }
    .header-slide .options .icon-main:hover {
      color: #dc3545; }
  .header-slide .header-selects .select-options {
    position: relative;
    margin-left: 16px;
    width: 250px;
    /* Remove IE arrow */
    /* Custom Select */
    /* Arrow */ }
    .header-slide .header-selects .select-options:last-child {
      width: 200px; }
    .header-slide .header-selects .select-options select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      padding: 0 .5em;
      color: #000000;
      cursor: pointer; }
    .header-slide .header-selects .select-options select::-ms-expand {
      display: none; }
    .header-slide .header-selects .select-options .select {
      position: relative;
      display: flex;
      width: 100%;
      height: 2.5em;
      line-height: 3;
      background: #FFFFFF;
      overflow: hidden;
      border-radius: 4px; }
    .header-slide .header-selects .select-options .select-arrow {
      position: absolute;
      z-index: 2;
      top: 4px;
      right: 12px;
      border-radius: 0 4px 4px 0;
      height: 100%;
      font-size: 1.2rem;
      cursor: pointer;
      pointer-events: none;
      -webkit-transition: .25s all ease;
      transition: .25s all ease;
      color: black; }
    .header-slide .header-selects .select-options:hover .select-arrow {
      color: #dc3545; }

/**
*   card-quick.scss
*   @description: Estilos de tarjetas quick
*/
.card-quick {
  margin: 0 16px;
  border-radius: 4px;
  padding: 8px 8px 8px 16px;
  background-color: #FFFFFF;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.05); }
  .card-quick:first-child {
    margin: 0 16px 0 0; }
  .card-quick:last-child {
    margin: 0 0 0 16px; }
  .card-quick .icon {
    padding-right: 16px; }
  .card-quick .info h5 {
    font-size: 1.4rem; }

/**
*   card-tables.scss
*   @description: Estilos de tarjetas de tablas
*/
.card-table {
  border-radius: 4px;
  padding: 16px;
  background-color: #FFFFFF;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.05);
  /*button {
        transition: color 300ms ease-in-out;
        background-color: transparent;
        color: black;
        &:hover {
            background-color: transparent;
            color: $primary;
        }
    }*/ }
  .card-table ::-webkit-scrollbar {
    width: 4px; }
  .card-table ::-webkit-scrollbar-thumb {
    background-color: #cccccc; }
  .card-table th {
    padding: 8px 0;
    border-bottom: 2px solid rgba(108, 117, 125, 0.4); }
  .card-table td {
    padding: 8px; }
  .card-table .th-options {
    width: 120px !important; }
  .card-table .th-options-small {
    width: 94px !important; }
  .card-table .th-options-huge {
    width: 250px !important; }
  .card-table thead tr:hover {
    background-color: #FFFFFF; }
  .card-table tr {
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out; }
    .card-table tr.row-active {
      background-color: #c3c3c3; }
    .card-table tr:hover {
      background-color: rgba(242, 242, 242, 0.6); }
  .card-table .btn-secondary {
    -webkit-transition: color 300ms ease-in-out;
    transition: color 300ms ease-in-out;
    background-color: transparent;
    color: black; }
    .card-table .btn-secondary:hover {
      background-color: transparent;
      color: #ea4235; }

.card-table-teoric {
  position: relative;
  /*.total-row {
        position: absolute;
        bottom: 0;
        width: 97%;
    }*/ }
  .card-table-teoric .total-budget {
    width: 171px; }
  .card-table-teoric .total-real {
    width: 205px; }
  .card-table-teoric .budget {
    width: 270px; }

.card-table-1 {
  width: 60%;
  margin-right: 32px; }
  .card-table-1 td {
    padding: 8px 0;
    border-bottom: 2px solid #f8f9fa; }

.card-table-2 {
  width: 40%;
  align-self: flex-start; }
  .card-table-2 td {
    border-bottom: 2px solid #f8f9fa; }

.card-table-guests {
  height: auto;
  max-height: none;
  max-height: initial; }

.card-table-incidents thead .description {
  width: 30%; }

/**
*   card-incident.scss
*   @description: Estilos de tarjetas de incidencias
*/
.card-incident .name {
  width: 20%; }

.card-incident .description {
  width: 50%; }

.card-incident .description-detail {
  font-size: 0.93rem; }

.card-incident .vivienda {
  width: 10%; }

.card-incident .fecha {
  width: 20%; }

.card-incident td {
  border-bottom: 2px solid #f8f9fa; }

/**
*   card-incident.scss
*   @description: Estilos de tarjetas de incidencias
*/
/**
*   card-incident.scss
*   @description: Estilos de tabla detalle de perfil
*/
.profile-detail {
  width: 100%; }
  .profile-detail .content {
    width: 100%;
    padding: 30px; }
    .profile-detail .content .menus {
      width: 50%; }
      .profile-detail .content .menus .no-select {
        cursor: pointer;
        background: gray;
        margin: 0 6px;
        padding: 2px 0; }
        .profile-detail .content .menus .no-select .butn {
          cursor: pointer;
          font-family: "Mukta", sans-serif;
          font-size: 0.9rem;
          font-weight: 600;
          -webkit-transition: all 200ms ease-in-out;
          transition: all 200ms ease-in-out;
          color: white; }
          .profile-detail .content .menus .no-select .butn:active {
            -webkit-transform: scale(0.95);
                    transform: scale(0.95); }
      .profile-detail .content .menus .option {
        background: #dc3545;
        cursor: pointer;
        margin: 0 6px;
        padding: 2px 0; }
        .profile-detail .content .menus .option:hover {
          border: 1px solid; }
        .profile-detail .content .menus .option .butn {
          color: white; }
    .profile-detail .content .detail-table {
      border: 1px solid #b9b9b9;
      box-shadow: 0px 0 2px 2px #eee;
      border-radius: 4px;
      padding: 12px;
      /*.container-complete {
                //border: 1px solid black;
                max-height: 300px;
                overflow-y: scroll;
            }*/ }
      .profile-detail .content .detail-table .menu2 {
        border-bottom: 1px solid gray;
        padding-bottom: 4px; }
        .profile-detail .content .detail-table .menu2 h4 {
          font-weight: 700;
          color: gray; }
      .profile-detail .content .detail-table .btn-container {
        /*.btn-primary {
                    margin-right: 8px;
                }

                .btn-secondary {
                    background: #6c757d;
                    color: white;
                }*/ }
        .profile-detail .content .detail-table .btn-container button {
          width: 20%;
          padding: 4px 8px; }

/**
*   card-incidents.scss
*   @description: Estilos de tarjetas de incidencias en la página de incidencias
*/
.card-incidents .type {
  width: 150px;
  padding-right: 16px; }

.card-incidents .name {
  width: 150px; }

.card-incidents .date {
  width: 100px;
  padding: 0 16px; }

.card-incidents .description {
  width: 300px;
  padding: 0 16px; }

.card-incidents .resolution {
  width: 180px;
  padding: 0 16px; }

.card-incidents .more {
  width: 140px;
  padding: 0 8px; }

/**
*   modals.scss
*   @description: Estilos para modales
*/
/*---------------------- ESTILOS EN USO ------------------------*/
.modal {
  top: 50%;
  left: 50%;
  width: 50%;
  outline: none;
  max-height: 90%;
  -webkit-transition: max-height 1.5s ease-in-out;
  transition: max-height 1.5s ease-in-out;
  position: absolute;
  border-radius: 4px;
  background-color: #FFFFFF;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }
  .modal .btn-container button {
    width: 28%;
    padding: 4px 8px;
    margin-right: 8px; }
    .modal .btn-container button:last-child {
      margin-right: 0; }
  .modal .btn-container .btn-mini {
    width: 30%; }

.overlay-modal {
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5); }
  .overlay-modal ::-webkit-scrollbar {
    width: 6px; }
  .overlay-modal ::-webkit-scrollbar-thumb {
    background-color: #cccccc; }

.modal-delete {
  top: 50%;
  left: 50%;
  width: 50%;
  outline: none;
  max-height: 90%;
  -webkit-transition: max-height 1.5s ease-in-out;
  transition: max-height 1.5s ease-in-out;
  position: absolute;
  border-radius: 4px;
  background-color: #FFFFFF;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
  width: 40%; }

/* ACCIONES MODAL */
.show-modal {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s; }

.hide-modal {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: visibility 0s linear 0.25s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s; }

/*----------------------------------------------------------------*/
.modal-full .label {
  min-width: 23%;
  max-width: 24%; }

.modal-alert {
  width: 40%; }
  .modal-alert button {
    padding: 10px 22px; }

.modal-subfrac .label {
  min-width: 15%;
  max-width: 15%; }

.modal-scroll {
  overflow-y: scroll;
  height: 500px; }

.modal-content {
  width: 100%;
  padding: 30px;
  overflow: auto;
  max-height: 700px; }

.modal-content-incident {
  width: 100%;
  padding: 30px;
  overflow: auto;
  max-height: 700px; }

.buttons {
  padding-bottom: 16px; }

.modal-owners .search-content {
  flex-direction: row-reverse; }
  .modal-owners .search-content .left {
    width: 85%; }
  .modal-owners .search-content .search-cancel {
    padding: 20px 0px 0 8px; }

.modal-owners .label {
  min-width: 20%;
  max-width: 20%; }

.modal-owners .column-sub {
  margin-left: 24px;
  width: 90%; }

.modal-owners .label-small {
  min-width: 40%;
  max-width: 40%; }

.modal-owners .modal-content {
  overflow-y: scroll; }
  .modal-owners .modal-content .option {
    cursor: pointer;
    padding: 8px 0;
    border-top: 1px solid black;
    border-left: 1px solid black !important;
    border-right: 1px solid black; }
    .modal-owners .modal-content .option .butn {
      cursor: pointer;
      font-family: "Mukta", sans-serif;
      font-size: 1rem;
      font-weight: 600;
      -webkit-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
      .modal-owners .modal-content .option .butn:active {
        -webkit-transform: scale(0.95);
                transform: scale(0.95); }
  .modal-owners .modal-content .no-select {
    background: #f1f1f1;
    cursor: pointer;
    border-left: 2px solid white; }
    .modal-owners .modal-content .no-select .butn {
      cursor: pointer;
      font-family: "Mukta", sans-serif;
      font-size: 1rem;
      font-weight: 600;
      -webkit-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
      .modal-owners .modal-content .no-select .butn:active {
        -webkit-transform: scale(0.95);
                transform: scale(0.95); }
    .modal-owners .modal-content .no-select:hover {
      border: 1px solid; }
  .modal-owners .modal-content .menu {
    /*#ea4235*/
    background: #ea4235;
    border: 1px solid black; }
  .modal-owners .modal-content .menu2 {
    border-bottom: 2px solid rgba(108, 117, 125, 0.4); }
  .modal-owners .modal-content .container-complete {
    max-height: 300px;
    overflow-y: scroll; }

.modal-houses p {
  min-width: 20%; }

.modal-houses .inquilino-name {
  width: 65%; }

.modal-houses .inquilino-btns {
  width: 35%; }

.small-modal {
  top: 50%;
  left: 50%;
  width: 40%;
  outline: none;
  max-height: 90%;
  -webkit-transition: max-height 1.5s ease-in-out;
  transition: max-height 1.5s ease-in-out;
  position: absolute;
  border-radius: 4px;
  background-color: #FFFFFF;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white; }
  .small-modal .search-cancel-so {
    padding-left: 6px;
    width: 20%; }
    .small-modal .search-cancel-so .btn-secondary {
      margin-top: 4px; }

.image-modal {
  background-color: transparent !important;
  padding: 30px;
  top: 45%; }
  .image-modal .header-modals {
    background-color: transparent !important; }
  .image-modal img {
    width: 390px;
    height: auto; }

.input-form-content {
  /* Estilos label para filas con un solo input */
  /* Estilos label para filas con dos inputs */
  /* Estilos label para filas con tres inputs */ }
  .input-form-content .space {
    margin-left: 16px; }
  .input-form-content .label-gray {
    background-color: #eaeaea;
    padding: 2px 16px; }
  .input-form-content .text-area-gray {
    background-color: #eaeaea;
    padding: 8px;
    width: 100%;
    font-size: 0.93rem; }
  .input-form-content .label-normal {
    width: 20%; }
  .input-form-content .label-extra {
    width: 31%; }
  .input-form-content .label-medium {
    width: 35%; }
  .input-form-content .label-big {
    width: 45%; }
  .input-form-content .label-duo-huge {
    width: 115%; }
  .input-form-content .label-duo-big {
    width: 90%; }
  .input-form-content .label-duo-normal {
    width: 53%; }
  .input-form-content .label-triple-huge {
    width: 130%; }

/* Estilos nueva área común */
.column-img {
  width: 75%; }

#sidebar {
  height: 100%;
  width: 0;
  position: -webkit-sticky;
  position: sticky;
  height: 100vh;
  z-index: 1;
  top: 0;
  color: #FFFFFF;
  left: 0;
  background-color: #000000;
  overflow-x: hidden;
  -webkit-transition: 300ms;
  transition: 300ms; }
  #sidebar .accordion {
    background-color: #000000;
    color: #FFFFFF;
    cursor: pointer;
    min-width: 270px;
    width: 100%;
    position: relative;
    padding: 4px 16px;
    font-size: 1.2rem;
    border: none;
    text-align: left;
    outline: none;
    -webkit-transition: background-color .300s ease-in-out;
    transition: background-color .300s ease-in-out; }
    #sidebar .accordion i {
      position: absolute;
      right: 18px;
      top: 12px; }
  #sidebar .active, #sidebar .accordion:hover {
    background-color: #ea4235; }
  #sidebar .panel {
    background-color: #000000;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.2s ease-out;
    transition: max-height 0.2s ease-out; }
  #sidebar .content-side {
    width: 100%; }
    #sidebar .content-side .nav-item {
      padding-left: 16px;
      padding: 0px 32px;
      -webkit-transition: 300ms;
      transition: 300ms; }
      #sidebar .content-side .nav-item:hover {
        background-color: #6c757d8a; }
    #sidebar .content-side .active-item {
      background-color: #6c757d8a; }

#content {
  -webkit-transition: margin-left 300ms;
  transition: margin-left 300ms;
  width: 100%; }

.maxHeight {
  max-height: 136px !important; }

/*LEFT SIDEBAR*/
.rightsidebar {
  position: fixed;
  right: 0px;
  background-color: #000000;
  height: 100%;
  width: 0px;
  overflow-x: hidden;
  -webkit-transition: 300ms;
  transition: 300ms;
  z-index: 1;
  padding-top: 100px; }
  .rightsidebar.open {
    width: 220px;
    padding-left: 10px; }

.paginator .page {
  padding: 4px 13px;
  background-color: black;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 4px;
  margin: 4px;
  -webkit-transition: background-color 300ms;
  transition: background-color 300ms; }
  .paginator .page:hover {
    background-color: #ea4235; }

.paginator .active {
  background-color: #ea4235; }

.generic-filter select {
  min-width: 170px; }

.filter-date .get-reports {
  width: 100%; }
  .filter-date .get-reports .select-report {
    padding-right: 10px; }
  .filter-date .get-reports .data-from {
    width: 60%; }
  .filter-date .get-reports .data-to {
    width: 60%;
    padding-left: 10px; }
  .filter-date .get-reports .get-result {
    padding-left: 20px; }

.filter-date .get-results {
  width: 30%; }

/**
*   card-margin.scss
*   @description: Margen en Modales
*/
.colum-modal {
  margin: 2px;
  position: relative;
  border: red 1px solid;
  border-radius: 1em;
  padding: .5em; }

.title-modal {
  position: absolute;
  margin: 1em;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -19px;
  background-color: white; }

.col-sm {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.dinamic-modal-small {
  width: 45%; }

.dinamic-modal-normal {
  width: 50%; }

.dinamic-modal-big {
  width: 57%; }

.dinamic-modal-container {
  top: 50%;
  left: 50%;
  outline: none;
  max-height: 90%;
  position: absolute;
  border-radius: 4px;
  background-color: #FFFFFF;
  -webkit-transition: max-height 1.5s ease-in-out;
  transition: max-height 1.5s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }
  .dinamic-modal-container .title {
    text-align: left; }
  .dinamic-modal-container .modal-container {
    width: 90%; }
  .dinamic-modal-container .dinamic-header {
    padding: 8px 0px 8px 0px;
    border-radius: 4px 4px 0 0;
    background-color: #000000;
    width: 100%;
    height: 60px;
    position: relative; }
    .dinamic-modal-container .dinamic-header .title {
      color: white;
      font-weight: 500; }
    .dinamic-modal-container .dinamic-header .btn-modal-close {
      position: absolute;
      right: 2.3%;
      top: 19%;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background-color: #000000;
      color: white;
      font-size: 1.4rem;
      -webkit-transition: background-color 400ms ease-in-out;
      transition: background-color 400ms ease-in-out; }
      .dinamic-modal-container .dinamic-header .btn-modal-close:hover {
        background-color: #6c757d86; }
  .dinamic-modal-container .dinamic-body {
    height: auto;
    max-height: 500px;
    overflow-y: scroll; }
  .dinamic-modal-container .dinamic-footer {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .dinamic-modal-container .dinamic-footer .btn-action-success {
      width: 25%;
      padding: 6px 10px;
      margin-right: 8px;
      font-size: 1rem;
      font-weight: 800;
      color: white;
      -webkit-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      background-color: #6c757d; }
      .dinamic-modal-container .dinamic-footer .btn-action-success i {
        font-size: 1rem; }
      .dinamic-modal-container .dinamic-footer .btn-action-success:last-child {
        margin-right: 0; }
      .dinamic-modal-container .dinamic-footer .btn-action-success:hover {
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.4);
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        -webkit-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s; }
      .dinamic-modal-container .dinamic-footer .btn-action-success:hover {
        background-color: #8c949b; }
    .dinamic-modal-container .dinamic-footer .btn-action-cancel {
      width: 25%;
      padding: 6px 10px;
      margin-right: 8px;
      font-size: 1rem;
      font-weight: 800;
      color: white;
      -webkit-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      background-color: #ea4235; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel i {
        font-size: 1rem; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel:last-child {
        margin-right: 0; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel:hover {
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.4);
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        -webkit-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s; }
      .dinamic-modal-container .dinamic-footer .btn-action-cancel:hover {
        background-color: #eb5b51; }

.overlay {
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: fixed;
  -webkit-transition: height 1500ms ease-in-out;
  transition: height 1500ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.5); }
  .overlay ::-webkit-scrollbar {
    width: 6px; }
  .overlay ::-webkit-scrollbar-thumb {
    background-color: #cccccc; }

.show-dinamic-modal {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s; }

.hide-dinamic-modal {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: visibility 0s linear 0.25s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, -webkit-transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s; }

.autocomplete {
  position: relative;
  display: inline-block; }
  .autocomplete .autocomplete-results {
    position: absolute;
    border: 2px solid #d4d4d4;
    border-bottom: 2px solid #d4d4d4;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    left: 0;
    right: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: #FFFFFF;
    padding: 0px; }
  .autocomplete .autocomplete-results.show {
    height: 300px;
    overflow: scroll; }
  .autocomplete .autocomplete-results.hide {
    height: 0px;
    border-bottom: none; }
  .autocomplete .autocomplete-items {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 2px solid #d4d4d4; }
    .autocomplete .autocomplete-items:hover {
      color: #FFFFFF;
      background-color: #899097; }

.tooltip {
  position: relative;
  display: inline-block; }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.776);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 80%; }
    .tooltip .tooltiptext::after {
      content: "";
      position: absolute;
      top: 27%;
      left: -28%;
      margin-left: -5px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent rgba(0, 0, 0, 0.776) transparent transparent; }
  .tooltip:hover .tooltiptext {
    visibility: visible; }

#subfraccionamiento {
  width: 200px; }

#subfraccionamiento-modal {
  width: 220px; }

/*      Páginas      */
/**
*   login.scss
*   @description: Estilos para la página de Login
*/
.login {
  height: 100vh; }
  .login .content {
    height: 100vh; }
  .login .card-login {
    width: 40%;
    padding: 16px;
    background-color: #FFFFFF;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.05); }
    .login .card-login img {
      height: 100px; }
  .login .input-container {
    padding: 0 32px; }
    .login .input-container input {
      width: 100%;
      border-radius: 4px;
      border: 2px solid rgba(159, 159, 159, 0.5);
      padding: 8px 8px; }
  .login .btn-container {
    padding: 0 32px; }
    .login .btn-container button {
      width: 100%;
      padding: 8px; }

#card-owners .info-content {
  width: 90%; }
  #card-owners .info-content .configuration-rows {
    padding-top: 12px;
    padding-left: 65px; }
  #card-owners .info-content .btn {
    padding: 6px 24px; }
  #card-owners .info-content .btn-secondary {
    background-color: #6c757d !important;
    color: white;
    margin-left: 16px; }

#card-owners .info-openpay {
  width: 75%; }
  #card-owners .info-openpay .btn {
    padding: 6px 24px; }
  #card-owners .info-openpay .btn-secondary {
    background-color: #6c757d !important;
    color: white;
    margin-left: 16px; }

#card-owners .edit-button {
  padding: 10px 0;
  border-bottom: 2px solid rgba(108, 117, 125, 0.4); }

.container-img {
  position: relative;
  width: 90%; }
  .container-img .img-duo-preview {
    min-height: 180px;
    max-height: 180px;
    width: 100%;
    object-fit: fill; }
  .container-img .image-area {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: .5s ease;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .container-img .middle-overlay {
    -webkit-transition: .5s ease;
    transition: .5s ease;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #ea41359f;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center; }
    .container-img .middle-overlay .btn-upload-image {
      width: 100%;
      height: 180px;
      cursor: pointer; }
      .container-img .middle-overlay .btn-upload-image input[type="file"] {
        display: none; }
      .container-img .middle-overlay .btn-upload-image i {
        font-size: 3.5rem;
        color: white;
        -webkit-transition: color 300ms ease-in-out;
        transition: color 300ms ease-in-out; }
  .container-img:hover .image-area {
    opacity: 0.3; }
  .container-img:hover .middle-overlay {
    opacity: 1; }

/*      Libs     */
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae; }

.react-datepicker-wrapper {
  width: 100%;
  display: inline-block; }

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem; }

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px; }

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px; }

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px; }

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px; }

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px; }

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative; }

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden; }

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc; }

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3; }

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default; }

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc; }

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px; }

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3; }

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default; }

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc; }

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3; }

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc; }

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3; }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }

.react-datepicker__month .react-datepicker__month-text {
  display: inline-block;
  width: 4rem;
  margin: 2px; }

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left; }

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block; }

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield; }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px; }

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0; }

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent; }

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer; }

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff; }

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
  background-color: #1d5d90; }

.react-datepicker__month--disabled {
  color: #ccc;
  pointer-events: none; }

.react-datepicker__month--disabled:hover {
  cursor: default;
  background-color: transparent; }

.react-datepicker__day,
.react-datepicker__month-text {
  cursor: pointer; }

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0; }

.react-datepicker__day--today,
.react-datepicker__month-text--today {
  font-weight: bold; }

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff; }

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover {
  background-color: #32be3f; }

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1 {
  color: magenta; }

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2 {
  color: green; }

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff; }

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover {
  background-color: #1d5d90; }

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff; }

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
  background-color: #1d5d90; }

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5); }

.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range {
  background-color: #f0f0f0;
  color: #000; }

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
  cursor: default;
  color: #ccc; }

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover {
  background-color: transparent; }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
  background-color: #216ba5; }

.react-datepicker__month-text:hover {
  background-color: #f0f0f0; }

.react-datepicker__input-container {
  width: 100%;
  position: relative;
  display: inline-block; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem; }

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer; }

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3; }

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae; }

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer; }

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc; }

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3; }

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3; }

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px; }

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px; }

.react-datepicker__close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\00d7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 0px;
  text-align: center; }

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  z-index: 2147483647; }

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem; }

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem; } }

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem; }

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent; }

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc; }

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3; }

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default; }

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc; }

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3; }

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default; }

