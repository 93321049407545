.generic-filter {
    select {
        min-width: 170px;
    }
}

.filter-date {
    .get-reports {
        width: 100%;

        .select-report {
            padding-right: 10px;
        }

        .data-from {
            width: 60%;
        }

        .data-to {
            width: 60%;
            padding-left: 10px;
        }

        .get-result {
            padding-left: 20px;
        }
    }

    .get-results {
        width: 30%;
    }
}