/**
*   login.scss
*   @description: Estilos para la página de Login
*/

.login {

    height: 100vh;

    .content { height: 100vh; }

    .card-login {
        width: 40%;
        padding: $paddingMedium;
        background-color: $white;
        box-shadow: 1px 0 20px rgba(0,0,0,.05);

        img { height: 100px; }

    }

    .input-container {
        padding: 0 $paddingLarge;
        input {
            width: 100%;
            border-radius: 4px;
            border: 2px solid rgba($gray, 0.5);
            padding: $paddingNormal $paddingNormal;
        }
    }

    .btn-container {
        padding: 0 $paddingLarge;
        button {
            width: 100%;
            padding: $paddingNormal;
        }
    }    

}