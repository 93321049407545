.container-img {
	position: relative;
	width: 90%;

	.img-duo-preview {
		min-height: 180px;
		max-height: 180px;
		width: 100%;
		object-fit: fill;
	}

	
	.image-area {
		opacity: 1;
		display: block;
		width: 100%;
		height: auto;
		transition: .5s ease;
		backface-visibility: hidden;
	}
	
	.middle-overlay {
		transition: .5s ease;
		opacity: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: #ea41359f;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		text-align: center;
		.btn-upload-image {
			width: 100%;
			height: 180px;
			cursor: pointer;

			input[type="file"] { display: none; }
			i {
				font-size: 3.5rem;
				color: white;
				transition: color 300ms ease-in-out;
				
			}

			
		}
		
	}

	&:hover {
		.image-area {	
			opacity: 0.3;
		}
	
		.middle-overlay {
			opacity: 1;
		}
	}
}
  